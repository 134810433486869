import styled from 'styled-components';

import { Color } from '~/styles/colors';
import { MediaQuery } from '~/styles/layout';
import { H2_HOME } from '~/styles/typography';
import GatsbyImage from 'gatsby-image';

// styled-components has an issue with union types used by GatsbyImage
export const MyPhoto = styled<any>(GatsbyImage)`
  object-fit: contain;
  flex: 1;
  margin-bottom: 16px;

  @media ${MediaQuery.TABLET_UP} {
    flex: 0 0 350px;
    order: 1;
    margin-bottom: 0;
    margin-left: 16px;
  }
`;

export const Top = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  background: ${Color.BRAND};
  margin-bottom: 48px;

  @media ${MediaQuery.TABLET_UP} {
    margin-bottom: 96px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    text-align: right;
    padding: 16px 16px 0;

    ${H2_HOME}

    @media ${MediaQuery.TABLET_UP} {
      flex-direction: row;
      max-width: 100%;
      justify-content: center;
      align-items: center;
      padding: 32px 16px 0;
    }
  }

  :after {
    display: block;
    position: absolute;
    bottom: -48px;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 48px 0 0 100vw;
    border-color: ${Color.BRAND} transparent;

    @media ${MediaQuery.TABLET_UP} {
      bottom: -96px;
      border-width: 96px 0 0 100vw;
    }
  }
`;

export const Who = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
  }
`;

export const That = styled.div`
  @media ${MediaQuery.TABLET_UP} {
    width: 768px;
    padding-left: 72px;
  }

  h2 {
    ${H2_HOME}

    text-align: left;
  }
`;

export const Roles = styled.ul`
  list-style: none;

  > li {
    margin-bottom: 32px;

    &::before {
      display: inline-flex;
      content: '';
      width: 0.75em;
      height: 0.75em;
      border-radius: 50%;
      background: ${Color.FOREGROUND};
      margin-right: 0.25em;
    }
  }
`;

export const RoleLinks = styled.ul`
  list-style: none;
  margin: 12px 0 16px 56px;

  > li {
    display: flex;
    margin-bottom: 16px;

    > a {
      display: flex;
      align-items: center;
    }

    svg {
      width: 48px;
      height: 48px;
      margin-right: 8px;

      @media ${MediaQuery.TABLET_UP} {
        margin-right: 16px;
      }
    }
  }
`;
