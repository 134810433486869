import React from 'react';
import Layout from '~/components/Layout';
import { Top, Who, That, Roles, RoleLinks, MyPhoto } from './home/style';
import { PageProps, graphql } from 'gatsby';
import GithubIcon from '~/assets/icons/social/github.svg';
import LinkedinIcon from '~/assets/icons/social/linkedin.svg';
import YoutubeIcon from '~/assets/icons/social/youtube.svg';
import InstagramIcon from '~/assets/icons/social/instagram.svg';
import FacebookIcon from '~/assets/icons/social/facebook.svg';
import siteMetadata from '~/config/metadata';

const HomePage = ({ data }: PageProps<GatsbyTypes.HomePageQuery>) => {
  return (
    <Layout inHome>
      <Top>
        <div>
          <MyPhoto fluid={data.file!.childImageSharp!.fluid!} loading="eager" />
          <span>&mdash; o próprio</span>
        </div>
      </Top>
      <Who>
        <h1>QUEM?</h1>
        <That>
          <h2>Aquele...</h2>
          <Roles>
            <li>
              <span>desenvolvedor apaixonado por frontend</span>
              <RoleLinks>
                <li>
                  <a href="https://github.com/aquelehugo">
                    <GithubIcon /> aquelehugo
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/in/aquelehugo">
                    <LinkedinIcon />
                    aquelehugo
                  </a>
                </li>
              </RoleLinks>
            </li>
            <li>
              <span>músico d'Aquele Canal</span>
              <RoleLinks>
                <li>
                  <a href={siteMetadata.links.youtubeMusicChannel}>
                    <YoutubeIcon />
                    aquelecanaldohugo
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/aquelehugo">
                    <InstagramIcon /> aquelehugo
                  </a>
                </li>
                <li>
                  <a href="https://fb.com/aquelehugooficial">
                    <FacebookIcon />
                    aquelehugooficial
                  </a>
                </li>
              </RoleLinks>
            </li>
            {/* <li>
              <span>que ama compartilhar o que aprende</span>
              <RoleLinks>
                <li>
                  <Link to="/blog">Aquele Blog</Link>
                </li>
              </RoleLinks>
            </li> */}
          </Roles>
        </That>
      </Who>
    </Layout>
  );
};

export const query = graphql`
  query HomePage {
    file(relativePath: { eq: "home/images/face.jpg" }) {
      childImageSharp {
        fluid(fit: CONTAIN) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default HomePage;
